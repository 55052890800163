import React, { useState, useEffect } from 'react';


import axios from 'axios';
//import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
//import { Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Box, Container, Checkbox, FormControlLabel } from '@mui/material';


import ProductCard from './components/ProductCard';
import SearchPopup from './components/SearchPopup';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import Footer from './Footer';
import './App.css'; // CSS 파일 가져오기


const apiUrl = process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PRODUCTION
    : process.env.REACT_APP_API_URL;


function AdminPage({ setIsAdmin }) {
    
    useEffect(() => {
        setIsAdmin(true); // admin 상태를 true로 설정
    }, [setIsAdmin]);

    return About();
}


function App() {
    
    const [isAdmin, setIsAdmin] = useState(false); // admin 상태 관리
    return (
        <Router>
            <AppBar position="static" sx={{ backgroundColor: '#4b4c4c' }}>
                <Toolbar
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // 로고 위, 메뉴 아래 배치
                        alignItems: 'center',    // 중앙 정렬
                        gap: 3,                  // 로고와 메뉴 사이 간격
                        overflowX: 'hidden', // 좌우 스크롤 제거
                    }}
                >
                    {/* 로고 */}
                    <Link to="/" style={{ textDecoration: 'none' }}>
                        <img
                            src="/images/logo.png"
                            alt="Company Logo"
                            style={{ transform: 'scale(0.6)', marginBottom: -24 }} // 로고와 메뉴 간 여백
                        />
                    </Link>

                    {/* 메뉴 버튼 */}
                    <Box
                        sx={{
                            display: 'flex',       // 가로 정렬
                            justifyContent: 'center', // 중앙 정렬
                            gap: 2,                // 버튼 간 간격
                            flexWrap: 'wrap',      // 버튼이 화면을 벗어나면 줄바꿈
                            marginTop: '0px',      // 버튼을 위로 올림
                        }}
                    >
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#888888',
                                color: 'white',
                                '&:hover': { backgroundColor: '#115293' },
                                minWidth: { xs: '90px', sm: 220 }, // 버튼 너비
                                marginBottom: '18px', // 버튼 아래 여백 추가
                            }}
                            component={Link}
                            to="/products"
                        >
                            중고제품
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#888888',
                                color: 'white',
                                '&:hover': { backgroundColor: '#2e7d32' },
                                minWidth: { xs: '90px', sm: 220 }, // 버튼 너비
                                marginBottom: '18px', // 버튼 아래 여백 추가
                            }}
                            component={Link}
                            to="/Newproducts"
                        >
                            신제품
                        </Button>
                    </Box>
                </Toolbar>


            </AppBar>
            <Container sx={{ mt: 4, paddingBottom: '120px' }}>
                <Routes>
                    <Route path="/" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/admin" element={<AdminPage setIsAdmin={setIsAdmin} />} />
                    <Route path="/products" element={<Products isAdmin={isAdmin} isNewproducts={false} />} />
                    <Route path="/Newproducts" element={<Products isAdmin={isAdmin} isNewproducts={true} />} />

                </Routes>
            </Container>
            <Footer />
        </Router>
    );
}


function About() {

    var arstr = [
        [
            "중고 트랙터 :  품질이 보증된 판매",
            "지셈은 엄격한 품질 검사를 거친 중고 농업 트랙터를 제공합니다.최상의 상태를 유지하는 제품들로, 새 기계와 같은 성능과 가치를 경험할 수 있습니다.",
        ],

        [
            "신제품 농기계 : 가성비 높은 제품",
            "현대 농업의 다양한 요구를 충족시키는 혁신적이고 효율적인 신제품 농기계를 만나보세요. 비용 대비 최고의 성능을 자랑하는 제품들로 농업 생산성을 극대화합니다.",
        ],

        [
            "신뢰받는 전문성", "다년간의 경험과 성과를 통해 농업 기계 분야에서 확고한 신뢰를 구축했습니다.",
            "탁월한 품질", "중고 제품부터 신제품까지, 모든 제품은 엄격한 검사와 테스트를 통해 제공됩니다.",
            "경쟁력 있는 가격", "뛰어난 품질의 농기계를 합리적인 가격에 제공합니다.",
            "지셈과 함께하여 농업의 성공을 함께 이루세요.",
            "제품 문의 및 자세한 상담을 원하시면 언제든지 연락주시기 바랍니다."
        ],

    ];


    return (



        <Box sx={{ backgroundColor: '#f5f5f5', borderRadius: 2, p: { xs: 2, md: 4 } }}>
            <Typography variant="h4"  sx={{ fontSize: '1.6rem' }}>
                신뢰받는 농업 기계 파트너
            </Typography>
            <Typography variant="h6" gutterBottom>
                지셈은 신뢰와 품질을 바탕으로 농업 기계 산업을 선도하는 전문 기업입니다. 
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, gap: 2, mt: 4 }}>
                {[1, 2].map((item) => (
                    <Box key={item} sx={{ width: { xs: '100%', sm: '30%' }, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: { xs: 'auto', sm: 450 } }}>
                        <Box>
                            <Typography variant="h6" gutterBottom>
                                {arstr[item-1][0]}
                            </Typography>
                            <Typography variant="body2">
                                {arstr[item - 1][1]}
                            </Typography>

                        </Box>
                        <img
                            src={`/images/p${item}.jpg`}
                            alt={`Agriculture ${item}`}
                            style={{ width: '100%', marginTop: 10, borderRadius: 8 }}
                        />
                    </Box>
                ))}

                <Box  sx={{ width: { xs: '100%', sm: '30%' }, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: { xs: 'auto', sm: 450 } }}>
                    <Box>
                        <Typography variant="h6">
                            {arstr[2][0]}
                        </Typography>
                        <Typography variant="body2" >
                            {arstr[2][1]}
                        </Typography>

                        <Typography variant="h6">
                            {arstr[2][2]}
                        </Typography>
                        <Typography variant="body2">
                            {arstr[2][3]}
                        </Typography>

                        <Typography variant="h6">
                            {arstr[2][4]}
                        </Typography>

                        <Typography variant="body2" sx={{ marginBottom: 5 }}>
                            {arstr[2][5]}
                        </Typography>

                        <Typography variant="body1">
                            {arstr[2][6]}
                        </Typography>

                        <Typography variant="body1">
                            {arstr[2][7]}
                        </Typography>


                    </Box>
                </Box>

            </Box>
        </Box>
    );
}


function Products({ isAdmin ,isNewproducts}) {

    console.log("isNewproducts", isNewproducts);
    const [selectedProduct, setSelectedProduct] = React.useState(null);
    const [imageIndex, setImageIndex] = React.useState(0); // 이미지 인덱스 추가
   
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [sortOption, setSortOption] = useState(null);

    const [products, setProducts] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [criteria, setCriteria] = useState(null);




    const [isSearchPopupOpen, setSearchPopupOpen] = useState(false); // 팝업 상태 관리




    const handleOpenSearch = () => setSearchPopupOpen(true); // 팝업 열기
    const handleCloseSearch = () => setSearchPopupOpen(false); // 팝업 닫기
    const handleSearch = async (newCriteria) => {
        setCriteria(newCriteria);
    };

    const maxPageButtons = 5; // 표시할 페이지 버튼의 최대 개수

    

    useEffect(() => {
        console.log("API 호출 - 페이지, 카테고리, 정렬 옵션, 검색 조건:", page, selectedCategory, sortOption, criteria);

        axios.get(`${apiUrl}/api/products`, {
            params: {
                page,
                category: selectedCategory !== 'all' ? selectedCategory : undefined,
                sort: sortOption || undefined,
                criteria: criteria || undefined,
                admin: isAdmin,// 상태 값 포함
                isNew: isNewproducts // isNewproducts 상태 값 포함
            }
        })
            .then((response) => {
                if (response.data && response.data.products) {
                    setProducts(response.data.products);
                    setTotalPages(Math.ceil(response.data.total / 20));
                } else {
                    setProducts([]);
                }
            })
            .catch((error) => {
                console.error('Error fetching products:', error);
            });
    }, [page, selectedCategory, sortOption, criteria, isNewproducts]);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setPage(newPage);
        }
    };

    const handleNextImage = () => {
        if (selectedProduct && selectedProduct.ImageArray) {
            setImageIndex((prevIndex) => (prevIndex + 1) % selectedProduct.ImageArray.length);
        }
    };

    const handlePreviousImage = () => {
        if (selectedProduct && selectedProduct.ImageArray) {
            setImageIndex((prevIndex) => (prevIndex - 1 + selectedProduct.ImageArray.length) % selectedProduct.ImageArray.length);
        }
    };


    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const handleSortOption = (option) => {
        setSortOption(option);
    };


    const getPageNumbers = () => {
        const pages = [];
        const startPage = Math.max(1, page - Math.floor(maxPageButtons / 2));
        const endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };
    

    const filteredProducts = React.useMemo(() => {
        if (selectedCategory === 'all') {
            return products;
        }
        return products.filter((product) => product.category === selectedCategory);
    }, [products, selectedCategory]);
    return (
        <Box>
            {selectedProduct ? (
                <Box sx={{ paddingBottom: '100px' }}>
                    <Button
                        onClick={() => setSelectedProduct(null)}
                        variant="contained"
                        sx={{
                            backgroundColor: '#1976d2',
                            color: 'white',
                            '&:hover': { backgroundColor: '#115293' },
                            mt: 1,
                            mb: 2,
                            px: 4,
                            py: 1,
                            borderRadius: '20px',
                            fontWeight: 'bold'
                        }}
                    >
                        이전페이지
                    </Button>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0 }}>
                        <img
                            src={selectedProduct.ImageArray[imageIndex]}
                            alt={`Product ${imageIndex + 1}`}
                            style={{ width: '100%', maxWidth: 600, height: 'auto' }}
                        />
                        <Typography variant="body1" sx={{ mt: 0 }}>{`${imageIndex + 1} / ${selectedProduct.ImageArray.length}`}</Typography>
                        <Box sx={{ display: 'flex', gap: 0, mt: 0 }}>
                            <Button onClick={handlePreviousImage}><ArrowBackIos /></Button>
                            <Button onClick={handleNextImage}><ArrowForwardIos /></Button>
                        </Box>
                        <Box sx={{ textAlign: 'center', mt: 0, width: '100%', maxWidth: 600 }}> {/* 이미지 크기와 동일한 maxWidth 설정 */}
                            <Typography variant="h4" gutterBottom>{selectedProduct.Title}</Typography>
                            <Box
                                component="table"
                                sx={{
                                    width: '100%',
                                    borderCollapse: 'collapse',
                                    '& td, & th': {
                                        border: '1px solid #ddd',
                                        padding: '8px',
                                    },
                                    '& th': {
                                        width: { xs: '50%', sm: '40%', md: '30%' },
                                        backgroundColor: '#f2f2f2',
                                        textAlign: 'left',
                                        verticalAlign: 'top',
                                    },
                                    '& td': {
                                        width: { xs: '50%', sm: '60%', md: '70%' },
                                    }
                                }}
                            >
                                <tbody>
                                    {/* 테이블에 출력할 데이터 */}
                                    {selectedProduct.list && selectedProduct.list
                                        .filter(item => item.key !== '설명') // "설명"인 항목은 필터링
                                        .map((item, index) => (
                                            <tr key={index}>
                                                <th>{item.key}</th>
                                                <td dangerouslySetInnerHTML={{ __html: item.value }}></td>
                                            </tr>
                                        ))}
                                </tbody>
                            </Box>
                            {/* "설명" 데이터 */}
                            {selectedProduct.list && selectedProduct.list
                                .filter(item => item.key === '설명') // "설명"인 항목만 필터링
                                .map((item, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            backgroundColor: '#f9f9f9',
                                            borderRadius: 2,
                                            p: 2,
                                            mt: 4,
                                            textAlign: 'left', // 내용 왼쪽 정렬
                                        }}
                                    >
                                        <Typography variant="h6" gutterBottom>설명</Typography>
                                        <Typography variant="body1" dangerouslySetInnerHTML={{ __html: item.value }}></Typography>
                                    </Box>
                                ))}

                        </Box>
                    </Box>
                </Box>
            ) : (
                    <Box
                        sx={{
                                display: 'flex',
                                flexWrap: 'wrap', // 줄바꿈 허용
                                gap: 2,
                                mt: 4,
                                width: '100%', // 부모 크기 초과 방지
                                overflowX: 'hidden', // 좌우 스크롤 제거
                            justifyContent: { xs: 'center', sm: 'flex-start' }, // 반응형 정렬
                            alignItems: 'center', // 세로 중앙 정렬 (필요 시)
                            textAlign: 'center', // 텍스트 중앙 정렬
                            
                          }}>
                        {/* 검색 팝업 */}
                        <SearchPopup
                            open={isSearchPopupOpen}
                            onClose={handleCloseSearch}
                            onSearch={handleSearch}
                            isAdmin={isAdmin}
                        />

                        <Box>
                            <Typography variant="h4" gutterBottom>제품 목록</Typography>

                            
                            <Typography variant="h7" gutterBottom>{isNewproducts==1 &&'주문 안내 :  최초 주문은 최소 3기 이상부터 가능합니다. 재주문은 10기 이상부터 주문이 가능합니다. '}</Typography>
                            

                            <Box sx={{
                                display: 'flex', gap: 2, mt: 2,
                                 justifyContent: 'center', // 가로 중앙 정렬
                                alignItems: 'center', // 세로 중앙 정렬 (필요 시)
                                textAlign: 'center', // 텍스트 중앙 정렬
                            }}>
                                <Button
                                    variant={sortOption === 'price' ? 'contained' : 'outlined'}
                                    onClick={() => handleOpenSearch()}
                                >
                                    검색
                                </Button>

                                <Button
                                    variant={sortOption === 'date' ? 'contained' : 'outlined'}
                                    onClick={() => handleSortOption('date')}
                                >
                                 최신순
                                </Button>
                            </Box>

                            {/* 제품 리스트 */}
                            <Box sx={{
                                display: 'flex',
                                flexWrap: 'wrap', // 줄바꿈 허용
                                gap: 2,
                                mt: 4,
                                width: '100%', // 부모 컨테이너가 화면에 꽉 차도록 설정
                                boxSizing: 'border-box', // 패딩 포함 크기 조정
                                padding: { xs: '0 8px', sm: '0 16px' }, // 양쪽에 약간의 여백 추가 (선택 사항)
                                justifyContent: 'center', // 가로 중앙 정렬                                
                            }}>
                                {filteredProducts.map((product) => (
                                    <ProductCard
                                        key={product._id}
                                        product={product}
                                        onClick={() => setSelectedProduct(product)}
                                    />
                                ))}
                            </Box>
                        </Box>
                    
                        <Box sx={{
                            display: 'flex',
                            mt: 2,
                            gap: 0,
                            width: '100%', // 부모 크기 초과 방지
                            overflowX: 'hidden', // 좌우 스크롤 제거
                            justifyContent: 'center', // 가로 중앙 정렬
                            alignItems: 'center', // 세로 중앙 정렬 (필요 시)
                            textAlign: 'center', // 텍스트 중앙 정렬
                        }}>
                        <Button onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
                            이전
                        </Button>
                        {getPageNumbers().map((pageNumber) => (
                            <Button
                                
                                key={pageNumber}
                                onClick={() => handlePageChange(pageNumber)}
                                variant={pageNumber === page ? "contained" : "outlined"}
                                sx={{
                                    minWidth: '40px', // 버튼 최소 폭 설정
                                    padding: '4px 8px', // 버튼 내부 패딩 조정
                                    fontSize: '12px', // 텍스트 크기 조정 (선택 사항)
                                }}
                            >
                                {pageNumber}
                            </Button>
                        ))}
                        <Button onClick={() => handlePageChange(page + 1)} disabled={page === totalPages}>
                            다음
                        </Button>
                    </Box>
                    <Typography sx={{ textAlign: 'center', mt: 0 }}>
                        {`Result ${(page - 1) * 20 + 1} - ${Math.min(page * 20, totalPages * 20)} of ${totalPages * 20}`}
                    </Typography>
                </Box>
            )}
        </Box>
    );
}




function Contact() {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                연락처
            </Typography>
            <Typography variant="body1">담당자: 유호춘</Typography>
            <Typography variant="body1">핸드폰: +82 10-8034-5893</Typography>
            <Typography variant="body1">이메일: gsem@gmail.com</Typography>
        </Box>
    );
}

export default App;
